<template>
  <b-field
    :horizontal="horizontal"
    :label="label"
    :label-for="id || _uid.toString()"
  >
    <b-select
      :id="id || _uid.toString()"
      v-model="company"
      :loading="loading"
      :required="required"
      @input="select"
    >
      <option v-if="enableAll" :value="null">{{ $t("all") }}</option>
      <option
        v-for="c in companies"
        :key="c.id"
        :value="c['@id']"
        :disabled="c.blocked"
      >
        {{ c.name }}
        {{ c.blocked ? `(${$t("deactivated")})` : "" | lc }}
      </option>
    </b-select>
  </b-field>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    companies: {
      type: Array,
      required: true,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    enableAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      company: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.company = value;
      },
    },
  },
  methods: {
    select(value) {
      this.$emit("input", value);
    },
  },
};
</script>
