<template>
  <div>
    <b-loading v-model="loading"></b-loading>

    <div
      v-if="!loading"
      class="is-flex is-flex-wrap-wrap is-align-items-center my-4"
    >
      <h1 class="mr-5">
        <span>{{ $tc("accessory", 2) }} &ndash;</span>
        {{ accessory.name }}
      </h1>
      <b-button
        type="is-primary"
        icon-left="pencil"
        tag="router-link"
        :to="{ name: 'AccessoryEdit', params: { id: $route.params.id } }"
      >
        {{ $t("edit") }}
      </b-button>
    </div>

    <div v-if="!loading" class="card">
      <div class="card-content pt-3">
        <BackButton
          :parent-route="{ name: 'DeviceIndex', query: { tab: 'accessories' } }"
        ></BackButton>

        <table class="table details-table">
          <tr>
            <th>{{ $tc("accessory_type") }}</th>
            <td>{{ accessory.productType.name }}</td>
          </tr>
          <tr>
            <th>{{ $t("name") }}</th>
            <td>{{ accessory.name }}</td>
          </tr>
          <tr>
            <th>{{ $t("manufacturing_date") }}</th>
            <td>{{ accessory.manufacturingDate | date }}</td>
          </tr>
          <tr>
            <th>{{ $t("manufacturing_costs") }}</th>
            <td>{{ accessory.manufacturingCosts | money }}</td>
          </tr>
          <tr>
            <th>{{ $t("delivery_date") }}</th>
            <td>{{ accessory.deliveryDate | date }}</td>
          </tr>
          <tr>
            <th>{{ $t("track_and_trace_number") }}</th>
            <td>{{ accessory.trackAndTraceNumber }}</td>
          </tr>
          <tr>
            <th>{{ $t("track_and_trace_last_known_location") }}</th>
            <td>{{ accessory.trackAndTraceLastKnownLocation }}</td>
          </tr>
          <tr>
            <th>{{ $t("total_life_time_usage") }}</th>
            <td>{{ accessory.totalLifeTimeUsage }}</td>
          </tr>
          <tr>
            <th>{{ $t("number") }}</th>
            <td>{{ accessory.number }}</td>
          </tr>
          <tr>
            <th>{{ $t("system_owner") }}</th>
            <td v-if="!userHasRoleSuperAdmin">
              {{ accessory.systemOwner.name }}
            </td>
            <td v-if="userHasRoleSuperAdmin">
              <router-link
                v-if="accessory.systemOwner"
                :to="{
                  name: 'CompanyView',
                  params: { id: accessory.systemOwner.id },
                }"
              >
                {{ accessory.systemOwner.name }}
              </router-link>
              <br />
              <b-button
                icon-left="swap-horizontal"
                class="mt-1"
                @click="showSystemOwnerModal = true"
              >
                {{ $t("system_owner_change") }}
              </b-button>
            </td>
          </tr>
          <tr v-if="userHasRoleSuperAdmin">
            <th>{{ $t("assigned_to") }}</th>
            <td>
              <router-link
                v-if="accessory.assignedTo"
                :to="{
                  name: 'CompanyView',
                  params: { id: accessory.assignedTo.id },
                }"
              >
                {{ accessory.assignedTo.name }}
              </router-link>
              <br />
              <b-button
                icon-left="swap-horizontal"
                class="mt-1"
                @click="showAssignedToModal = true"
              >
                {{ $t("assigned_to_change") }}
              </b-button>
            </td>
          </tr>
          <tr>
            <th>{{ $t("created") }}</th>
            <td>
              {{ accessory.createdAt | dateTime }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("updated") }}</th>
            <td>
              {{ accessory.updatedAt | dateTime }}
            </td>
          </tr>
        </table>

        <Services :accessory="accessory"></Services>
      </div>
    </div>

    <b-modal v-model="showSystemOwnerModal" :width="400" scroll="keep">
      <AccessoryCompanyModal
        :accessory="accessory"
        :type="'systemOwner'"
        @submit="load"
      ></AccessoryCompanyModal>
    </b-modal>

    <b-modal v-model="showAssignedToModal" :width="400" scroll="keep">
      <AccessoryCompanyModal
        :accessory="accessory"
        :type="'assignedTo'"
        @submit="load"
      ></AccessoryCompanyModal>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BackButton from "../../components/BackButton";
import AccessoryCompanyModal from "./partials/AccessoryCompanyModal";
import Services from "./partials/Services";

export default {
  name: "AccessoryView",
  components: {
    BackButton,
    AccessoryCompanyModal,
    Services,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      accessory: null,
      services: [],
      company: null,
      showSystemOwnerModal: false,
      showAssignedToModal: false,
    };
  },
  computed: {
    ...mapGetters(["userHasRoleSuperAdmin"]),
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["getAccessory"]),
    load() {
      this.loading = true;

      this.getAccessory(this.id).then(async (accessory) => {
        this.accessory = accessory;

        this.loading = false;
      });
    },
  },
};
</script>
