<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        {{ service ? $t("service_edit") : $t("service_add") }}
      </div>
    </div>
    <div class="card-content">
      <form class="mt-2" @submit.prevent="submit">
        <b-field :label="$t('type')" label-for="type" class="mb-5">
          <b-select id="type" v-model="form.serviceType" required>
            <option v-for="t in serviceTypes" :key="t.id" :value="t['@id']">
              {{ t.name | ucFirst }}
            </option>
          </b-select>
        </b-field>

        <div class="columns">
          <div class="column is-one-third">
            <CustomInput
              v-model="form.cost"
              :label="$t('cost')"
              :type="'number'"
              :horizontal="false"
              :icon="'currency-eur'"
            ></CustomInput>
          </div>
          <div class="column is-one-third">
            <CustomInput
              v-model="form.hours"
              :label="$t('hours')"
              :type="'number'"
              :horizontal="false"
              :icon="'clock'"
            ></CustomInput>
          </div>
          <div class="column is-one-third">
            <CustomInput
              v-model="form.hourCost"
              :label="$t('hour_cost')"
              :type="'number'"
              :horizontal="false"
              :icon="'currency-eur'"
            ></CustomInput>
          </div>
        </div>

        <CustomDatePicker
          v-model="form.date"
          :label="$t('date')"
          :horizontal="false"
          class="mb-5"
        ></CustomDatePicker>

        <CustomInput
          v-model="form.description"
          :label="$t('description')"
          :type="'textarea'"
          :horizontal="false"
          :required="false"
        ></CustomInput>

        <b-button
          type="is-primary"
          native-type="submit"
          icon-left="content-save"
          class="mt-2"
          :loading="submitting"
        >
          {{ $t("save") }}
        </b-button>
      </form>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapGetters } from "vuex";
import CustomDatePicker from "../../../components/forms/CustomDatePicker";
import CustomInput from "../../../components/forms/CustomInput";

export default {
  components: { CustomDatePicker, CustomInput },
  props: {
    accessory: {
      type: Object,
      default: null,
    },
    device: {
      type: Object,
      default: null,
    },
    service: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      submitting: false,
      serviceTypes: [],
      form: {
        serviceType: this.service?.serviceType["@id"],
        cost: this.service?.cost,
        hours: this.service?.hours,
        hourCost: this.service?.hourCost,
        date: this.service ? this.service.date : dayjs().format("YYYY-MM-DD"),
        description: this.service?.description,
      },
    };
  },
  computed: {
    ...mapGetters([
      "accessoryServiceTypesOrderedByName",
      "deviceServiceTypesOrderedByName",
    ]),
  },
  mounted() {
    if (this.accessory) {
      this.serviceTypes = this.accessoryServiceTypesOrderedByName;
      this.form.serviceType = this.serviceTypes[0]["@id"];
    }

    if (this.device) {
      this.serviceTypes = this.deviceServiceTypesOrderedByName;
      this.form.serviceType = this.serviceTypes[0]["@id"];
    }
  },
  methods: {
    ...mapActions([
      "createAccessoryService",
      "createDeviceService",
      "updateAccessoryService",
      "updateDeviceService",
    ]),
    async submit() {
      this.submitting = true;

      const payload = {
        ...this.form,
        cost: this.form.cost.toString(),
        hourCost: this.form.hourCost.toString(),
      };

      if (this.accessory) {
        if (this.service) {
          await this.updateAccessoryService({
            id: this.service.id,
            accessoryService: payload,
          });
        } else {
          await this.createAccessoryService({
            accessory: this.accessory["@id"],
            ...payload,
          });
        }
      }

      if (this.device) {
        if (this.service) {
          await this.updateDeviceService({
            id: this.service.id,
            deviceService: payload,
          });
        } else {
          await this.createDeviceService({
            device: this.device["@id"],
            ...payload,
          });
        }
      }

      this.$buefy.toast.open({
        message: this.service
          ? this.$t("service_updated")
          : this.$t("service_added"),
        type: "is-primary",
      });
      this.$emit("submit");
      this.$parent.close();
    },
  },
};
</script>
