<template>
  <b-button type="is-text" icon-left="arrow-left" @click="back">
    {{ $t("back") }}
  </b-button>
</template>

<script>
export default {
  props: {
    parentRoute: {
      type: Object,
      required: true,
    },
  },
  methods: {
    back() {
      this.$router.push(this.parentRoute);
    },
  },
};
</script>
