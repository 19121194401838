<template>
  <div>
    <b-collapse v-model="open" class="panel" animation="slide">
      <template #trigger>
        <div class="panel-heading">
          <h2>{{ $tc("service", 2) }}</h2>
          <b-icon :icon="open ? 'chevron-up' : 'chevron-down'"></b-icon>
        </div>
      </template>
      <div class="panel-block">
        <b-button icon-left="plus" @click="toggleServiceAddModal">
          {{ $t("service_add") }}
        </b-button>
        <b-table :data="services" default-sort="date" class="mt-4">
          <template #empty>
            {{ loading ? $t("loading") : $t("no_matching_results") }}
          </template>

          <b-table-column :label="$t('date')" field="date" sortable>
            <template v-slot="props">
              {{ props.row.date | date }}
            </template>
          </b-table-column>

          <b-table-column
            :label="$tc('service')"
            field="serviceType.name"
            sortable
          >
            <template v-slot="props">
              {{ props.row.serviceType.name | ucFirst }}
            </template>
          </b-table-column>

          <b-table-column :label="$tc('cost')" field="cost" numeric sortable>
            <template v-slot="props">
              {{ props.row.cost | money }}
            </template>
          </b-table-column>

          <b-table-column :label="$t('hours')" field="hours" numeric sortable>
            <template v-slot="props">
              {{ props.row.hours }} {{ $t("hours_abbreviation") }}
            </template>
          </b-table-column>

          <b-table-column
            :label="$t('hour_cost')"
            field="hourCost"
            numeric
            sortable
          >
            <template v-slot="props">
              {{ props.row.hourCost | money }}
            </template>
          </b-table-column>

          <b-table-column
            :label="$t('description')"
            field="description"
            sortable
          >
            <template v-slot="props">
              {{ props.row.description | truncate }}
            </template>
          </b-table-column>

          <b-table-column numeric>
            <template v-slot="props">
              <b-button
                icon
                size="is-small"
                type="is-text"
                @click="toggleServiceEditModal(props.row)"
              >
                <b-icon icon="pencil" size="is-small"></b-icon>
              </b-button>
            </template>
          </b-table-column>
        </b-table>
      </div>
    </b-collapse>

    <b-modal v-model="showServiceModal" :width="400" scroll="keep">
      <ServiceModal
        :accessory="accessory"
        :device="device"
        :service="serviceToEdit"
        @submit="load"
      ></ServiceModal>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ServiceModal from "./ServiceModal";

export default {
  components: { ServiceModal },
  props: {
    accessory: {
      type: Object,
      default: null,
    },
    device: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      open: true,
      services: [],
      showServiceModal: false,
      serviceToEdit: null,
    };
  },
  computed: {
    ...mapGetters([
      "findAccessoryServiceTypeByUri",
      "findDeviceServiceTypeByUri",
    ]),
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["getAllAccessoryServices", "getAllDeviceServices"]),
    async load() {
      let services;
      if (this.accessory) {
        services = await this.getAllAccessoryServices(this.accessory.id);
        services.forEach((s) => {
          s.serviceType = this.findAccessoryServiceTypeByUri(s.serviceType);
        });
      }
      if (this.device) {
        services = await this.getAllDeviceServices(this.device.id);
        services.forEach((s) => {
          s.serviceType = this.findDeviceServiceTypeByUri(s.serviceType);
        });
      }
      this.services = services;

      this.loading = false;
    },
    toggleServiceAddModal() {
      this.serviceToEdit = null;
      this.showServiceModal = true;
    },
    toggleServiceEditModal(service) {
      this.serviceToEdit = service;
      this.showServiceModal = true;
    },
  },
};
</script>
