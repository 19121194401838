<template>
  <b-field
    :horizontal="horizontal"
    :label="label"
    :label-for="id || _uid.toString()"
    :type="invalid ? 'is-danger' : ''"
    :message="invalid ? invalidMessage : ''"
  >
    <b-input
      :id="id || _uid.toString()"
      :value="text"
      :type="type"
      :required="required"
      :disabled="disabled"
      :placeholder="placeholder"
      :min="min"
      :step="step"
      :icon="icon"
      :icon-right="clearable ? 'close-circle' : ''"
      :icon-right-clickable="clearable"
      :class="{ 'has-text-right': type === 'number' }"
      @icon-right-click="clearIconClick"
      @input="input"
      @blur="blur"
    ></b-input>
  </b-field>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    horizontal: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    min: {
      type: Number,
      default: 0,
    },
    step: {
      type: Number,
      default: 1,
    },
    icon: {
      type: String,
      default: null,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    invalidMessage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      text: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (this.type === "number" && value !== null) {
          value = Math.round(value * 100) / 100;
          value = value.toString();
        }
        this.text = value;
      },
    },
  },
  methods: {
    input(value) {
      if (value === "") {
        value = null;
      }
      if (value && this.type === "number") {
        value = parseInt(value * 100) / 100;
      }
      this.$emit("input", value);
    },
    blur() {
      if (!this.text || this.type === "number") {
        return;
      }
      this.text = this.text.trim();
      this.$emit("input", this.text);
    },
    clearIconClick() {
      this.text = null;
      this.$emit("input", this.text);
    },
  },
};
</script>
